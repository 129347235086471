var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dapps--eth-blocks--block-quick-view-popup" },
    [
      _c("app-modal", {
        attrs: {
          width: "380px",
          show: _vm.showBlock,
          "has-buttons": false,
          "has-close-button": true,
          close: _vm.close,
        },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _c("h2", { staticClass: "textDark--text" }, [
                  _vm._v(_vm._s(_vm.blockNumber)),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-center justify-end py-5",
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.img, width: "300", height: "300" },
                    }),
                  ]
                ),
                _c("div", { staticClass: "textDark--text" }, [
                  _vm._v(" " + _vm._s(_vm.description) + " "),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "primary--text font-weight-medium cursor--pointer mt-3",
                    on: { click: _vm.navToBlock },
                  },
                  [_vm._v(" View Block details ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }